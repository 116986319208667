@import "../../../styles/variables";

.root {
  display: flex;
  flex-direction: column;
  @include computedVh(100, min-height);
  background-color: #f2f2f2;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 20px 5px 0 5px;
  flex-grow: 1;
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.25;

  text-align: center;
  letter-spacing: 0.0982612px;
}

.dates {
  margin-top: 7px;
  font-size: 20px;
  line-height: 1.35;
  text-align: center;
  letter-spacing: 0.166667px;
}

.partners {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 21px 30px 14px;
  border-top: 1px solid black;
}

.ftWrapper {
  font-size: 10px;
  line-height: 1.2;
  letter-spacing: 0.412699px;
}
.ftLogo {
  margin-top: 8px;
  width: 104px;
}
