.dontMiss {
  margin-top: 17px;
}
.news {
  margin-top: 25px;
}

.root {
  padding: 40px 24px 40px 24px;
}
