@import "../../styles/variables";

.root {
  width: 100%;
  height: 1px;
  border-top: 1px solid $primary-color;
  opacity: 0.3;
}

:global(.dark) .root {
  border-color: $secondary-color;
}

.rootVertical {
  height: 100%;
  width: 1px;
}
