@import "../../styles/variables";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include computedVh(100, min-height);
}

.body1 {
  font-weight: normal;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 1.16667px;
}
