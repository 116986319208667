$background-color: #fff;
$primary-color: black;
$secondary-color: white;
$text-color: black;
$text-color-primary: white;
$text-color-light: #a4a4a4;
$error-color: #ff0033;

$background-color-1: #fcfcfc;
$background-red: #e03808;
$background-gray: #7d7d7d;
$background-dark: #464646;

$header-height: 80px;
$subheader-height: 60px;
$frieze-banner-height: 44px;

$total-header-height: $header-height + $frieze-banner-height + 2px;

$z-index-header: 100;
$z-index-search: 110;
$z-index-modal: 1000;
$z-index-inquire: 90;

$input-underline-width: 1px;

$layout-paddings: 20px;

$letter-spacing-1: 0.78px;
$letter-spacing-2: 0.6px;

@mixin computedVh($value: 100, $prop: height) {
  #{$prop}: $value * 1vh;
  #{$prop}: calc(var(--vh, 1vh) * #{$value});
}

//desktop
$desktop-header-height: 58px;
$desktop-footer-height: 81px;
$desktop-side-col-width: 415px;

$lMinWidth: 1440px;
$mMaxWidth: 1439px;
$mMinWidth: 1025px;
$sMaxWidth: 1024px;
$sMinWidth: 768px;
$sMinHeight: 501px;
$xsMaxWidth: 767px;
$xsMaxHeight: 500px;

:export {
  lMinWidth: $lMinWidth;
  mMaxWidth: $mMaxWidth;
  mMinWidth: $mMinWidth;
  sMaxWidth: $sMaxWidth;
  sMinWidth: $sMinWidth;
  sMinHeight: $sMinHeight;
  xsMaxWidth: $xsMaxWidth;
  xsMaxHeight: $xsMaxHeight;
  totalHeaderHeight: $total-header-height;
}

@mixin buttonTransitionProps() {
  transition-duration: 70ms;
  transition-timing-function: ease-in-out;
}
