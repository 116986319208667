@import "../../styles/variables";
@import "../../styles/typography";

.root * {
  text-decoration: none;
  color: inherit;
}

.uppercase {
  text-transform: uppercase;
}

.darkScreenVariant {
  font-size: 1.25rem;
  line-height: 1.875;
  font-weight: 300;
  color: $text-color-primary;

  b {
    font-weight: 400;
  }
}

.titleVariant,
.title1Variant {
  font-weight: 500;
  color: $text-color;
  line-height: 1.25rem;
}

.title1Variant {
  font-size: 1.25rem;
  letter-spacing: 0.24px;
}

.captionVariant {
  margin-top: 10.5px;

  font-weight: 400;
  font-size: 1rem;
  font-style: italic;

  color: rgba($text-color, 0.6);
  line-height: 1.38;
}

.body1Variant {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.625rem;
  color: $text-color;

  b {
    font-weight: bold;
  }
}

.body2Variant {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $text-color;

  b {
    font-weight: 500;
  }
}

.center {
  text-align: center;
}

.h1 {
  @include h1;
}
.h2 {
  @include h2;
}
.h3 {
  @include h3;
}
.h4 {
  @include h4;
}
.h5 {
  @include h5;
}
.h6 {
  @include h6;
}
.p {
  @include p;
}

.dark {
  color: white;
}
