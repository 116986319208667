.text {
  font-size: 17px;
  line-height: 1.47;

  text-align: center;
  letter-spacing: 0.944444px;
}

.button {
  align-self: center;
  margin-bottom: 29px;
}

.buttonContent {
  width: 274px;
}

.buttonText {
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  letter-spacing: 0.288889px;
  line-height: 1.5;
}

.logo {
  margin: 0 auto;
  margin-top: 98px;
  margin-bottom: 20px;
}

.findOutMore {
  padding-top: 50px;
  margin-top: auto;
}
