.root {
  overflow: hidden;
  height: 0;
  // will-change: height;
}

.hidden {
  display: none;
}

.wrapper {
  // Hack to get children with a negative margin to not falsify the height computation.
  display: flex;
}

.wrapperInner {
  width: 100%;
}

.rootEntered {
  overflow: visible;
  height: auto;
}
