@import "../../styles/variables";

$border-width: 1px;

.root {
  display: block;
  text-decoration: none;
}

.content {
  display: block;
  width: 160px;
  background-color: $secondary-color;
  border: $border-width solid $primary-color;
  text-decoration: none;
}

.contained {
  border: 0;
}

.text {
  color: $text-color;
  line-height: 37px - $border-width * 2;
}

:global(.dark) .root:not(.disableDark) .content {
  border-color: rgba($secondary-color, 0.8);
  background-color: $background-dark;
}

:global(.dark) .root:not(.disableDark) .text {
  color: $text-color-primary;
}

//active

.root:disabled {
  .content {
    background-color: darken(white, 15%);
    border-color: lighten(black, 50%);
  }
  .text {
    color: lighten(black, 50%);
  }
}

//small

.small {
  .content {
    background-color: rgb(254, 254, 254);
    border: 0;
  }

  .text {
    font-size: 0.75rem;
    line-height: 29px;
  }
}

//color primary
.primaryColor,
.primaryColor:disabled,
.pressed:disabled {
  .content {
    background-color: $primary-color;
    border: $border-width solid $primary-color;
  }
  .text {
    color: $text-color-primary;
  }
}

.root:hover:enabled:not(.noHover):not(.pressed),
.root[href]:hover:not(.noHover):not(.pressed) {
  & .content {
    background-color: $primary-color;
    color: $secondary-color;
  }
  & .text {
    color: $secondary-color;
  }

  &.primaryColor .content {
    background-color: $primary-color;
    color: $secondary-color;
  }
}

:global(.dark) .root:not(.disableDark) {
  &.primaryColor,
  &.primaryColor:disabled,
  &.pressed:disabled {
    .content {
      background-color: $secondary-color;
      border: $border-width solid $text-color-primary;
    }
    .text {
      color: $background-dark;
    }
  }
}

:global(.dark)
  .root:hover:enabled:not(.noHover):not(.pressed):not(.disableDark),
:global(.dark) .root[href]:hover:not(.noHover):not(.pressed):not(.disableDark) {
  & .content {
    background-color: $secondary-color;
    color: $background-dark;
  }
  & .text {
    color: $background-dark;
  }

  &.primaryColor .content {
    background-color: $secondary-color;
    color: $background-dark;
  }
}

.content,
.text {
  transition-property: background-color, color;
  @include buttonTransitionProps();
}

.primaryColor:disabled {
  .text {
    color: darken($text-color-primary, 25%);
  }
}

.pressed:disabled {
  .text {
    color: $text-color-primary;
  }
}

.fullWidth {
  .root {
    width: 100%;
  }
  .content {
    width: auto;
  }
}
