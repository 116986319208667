.sectionTitle {
  margin-bottom: 21px;

  font-weight: 500;
  font-size: 27px;
  line-height: 32px;

  letter-spacing: 0.413793px;
}

.body {
  font-weight: normal;
  font-size: 17px;
  line-height: 1.24;
  text-decoration: none;
  letter-spacing: 0.432973px;
}

.date {
  font-weight: 500;
  font-size: 13px;
  line-height: 1.54;
  letter-spacing: 0.288889px;

  opacity: 0.55;
}

.divider {
  margin-top: 50px;
}

.image {
  display: block;
  width: 242px;
  height: 150px;
  object-fit: cover;
}
